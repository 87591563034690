import $ from 'jquery';
jQuery(document).ready(function() {
  console.log('1213');
  $('#show-passs').click(function(){
   var x = document.getElementById("user_password");
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
  });
});